import styled from "styled-components"
import heroLogo from "../../images/hero_logo.png"

const Wrapper = styled.div`
display:flex;
position:relative;
justify-content:center ;
align-items:center ;
height:350px;
background-color:#131339;
@media screen and (max-width:870px){
   height:unset;
   padding:35px 0;
}
`

const Logo = styled.img`
height:400px;
@media screen and (max-width:870px){
    width:60vw;
    height:auto;
    margin-bottom:-25px;
}
@media screen and (max-width:600px){
    width:70vw;
    height:auto;
    margin-bottom:-25px;
}
`

const FlexWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center ;
flex-direction:row ;
padding: 0 50px;
height:350px;
width:1000px;
gap:50px;
@media screen and (max-width:870px){
   flex-direction:column ;
   height:unset;

}
@media screen and (max-width:1400px){
    width:100vw;
}
`

const Paragraph = styled.p`
color:white;
text-align:center ;
font-size:18px;
font-weight:700;
@media screen and (min-width:470px){
    font-size:22px;
}
@media screen and (min-width:1020px){
    font-size:28px;
}
`

const TextWrapper = styled.div`
display:flex;
flex-direction:column ;
justify-content:center ;
`

const Button = styled.a`
text-decoration:none ;
color:white;
border: 4px solid #E94B49;
padding:20px 30px;
text-transform:uppercase ;
font-size:15px;
font-weight:900;
transition: .3s all ease ;

&:hover{
    border: 4px solid #E94B49;
    background-color: #E94B49;
}
`;

const ButtonsWrapper = styled.div`
display:flex;
padding-top:35px;
align-self:center;
@media screen and (max-width:870px){
   align-self:unset;
   justify-content:center ;
}
`

const Countdown = () => {
    const festivalDate = new Date('06/30/2023 5:0 PM');
    const now = new Date();
    const distance = festivalDate - now;

    const _second = 1000;
    const _minute = _second * 60;
    const _hour = _minute * 60;
    const _day = _hour * 24;

    const days = Math.floor(distance / _day);
    const hours = Math.floor((distance % _day) / _hour);
    const minutes = Math.floor((distance % _hour) / _minute);

    return (
        <Wrapper>
            <FlexWrapper>
        <Logo src={heroLogo}/>
        <TextWrapper>
        <Paragraph>DO FESTIWALU POZOSTAŁO:</Paragraph>
        <Paragraph>{days}&nbsp;DNI, {hours}&nbsp;GODZIN, {minutes}&nbsp;MINUT</Paragraph>
        <ButtonsWrapper>
        <Button href="https://fb.me/e/2CvyuCFsn" target="_blank">wezmę udział!</Button>
        </ButtonsWrapper>        
        </TextWrapper>
        </FlexWrapper>
        </Wrapper>
    )
}

export default Countdown;