

import styled from "styled-components"

const Wrapper = styled.a`
width:15vw;
height:auto;
text-decoration:none;
outline:none;
border:0;
@media screen and (min-width:1400px){
    width:200px;
    height:200px;
}
`
const Image = styled.img`
height:auto;
width:100%;
`

const Exhibitor = (props) => {
    return (
        <Wrapper href={props.link ? props.link : false} target="_blank">
            <Image src={props.img}/>
        </Wrapper>
    );
}

export default Exhibitor;