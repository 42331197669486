import styled from "styled-components"
import Heading from "../Heading/Heading"
import Navigation from "../Navigation/Navigation"
import Footer from "../Footer/Footer"
import Exhibitor from "./Exhibitor"
import { gastro, lifestyle } from "../../data"
const Wrapper = styled.div`
display:flex;
flex-direction:column;
@media screen and (min-width:1400px){
    padding:0 10vw;
}
`

const LogosWrapper = styled.div`
display:flex;
flex-direction:row ;
flex-wrap:wrap;
gap:10px;
margin-left:50px;
`


const Exhibitors = () => {
    return (
        <>
        <Navigation/>
        <Wrapper>
        <Heading>Wystawcy Gastro</Heading>
        <LogosWrapper>
            {gastro.map(el=><Exhibitor img={el.img} link={el.link}/>)}
        </LogosWrapper>
        <Heading>Wystawcy Inni</Heading>
        <LogosWrapper>
        {lifestyle.map(el=><Exhibitor img={el.img} link={el.link}/>)}
        </LogosWrapper>
        {/* <Heading>Wystawcy Craft</Heading>
        <LogosWrapper>
        {craft.map(el=><Logo src={el}/>)}
        </LogosWrapper> */}
        </Wrapper>
        <Footer/>
        </>
    )
}

export default Exhibitors;