import styled from "styled-components";
import Countdown from "../Countdown/Countdown";
import Footer from "../Footer/Footer";
import HeroSection from "../HeroSection/HeroSection";
import InfoIcons from "../InfoIcons/InfoIcons";
import Navigation from "../Navigation/Navigation";
import NewsShort from "../NewsShort/NewsShort";
import Partners from "../Partners/Partners";
import Video from "../Video/Video"
import afterMovie from "../../images/after_movie.mp4";
import Heading from "../Heading/Heading";

const Wrapper = styled.div`
position:relative;
`

const VideoWrapper = styled.div`

  height: auto;
  overflow: hidden;
  display:flex;
  flex-direction:column ;
  align-items:flex-start ;
  @media screen and (min-width:1400px){
    padding:0 10vw;
}
  /* @media (min-width: 1075px) {
    height: 42vw;
  } */
`;

const MainPage = () => 
<Wrapper>
<Navigation/>
<HeroSection/>
<InfoIcons/>
<Countdown/>
<NewsShort/>
<VideoWrapper>
<Heading>Aftermovie 2022</Heading>
        <Video
          loop
          muted={true}
          autoPlay={true}
          playsInline={true}
          controls={true}
          width="100vw"
          height="auto"
        >
          <source src={afterMovie} type="video/mp4" />
        </Video>
</VideoWrapper>
{/* <Partners/> */}
<Footer/>
</Wrapper>

export default MainPage;