import styled from "styled-components"
import heroPhoto from "../../images/hero_2022.png"
import heroPhotoMobile from "../../images/hero_2022_mobile.png"
import heroLogo from "../../images/hero_logo.png"
import free from "../../images/darmowy.png"

const Wrapper = styled.div`
display:flex;
margin:0 auto;
align-items:center ;
background-position: center;
background-size:cover;

@media screen and (max-width: 849px){
    background-image: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ), url(${heroPhotoMobile});
    width:100vw;
    min-height:100vw;
}
@media screen and (min-width: 850px){
    min-height:500px;
    background-image: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ), url(${heroPhoto});
}
@media screen and (min-width: 1400px){
    min-height:700px;
}
`;

const InfoWrapper = styled.div`
display:flex;
flex-direction:column;
align-items:center ;
margin:50px auto;
width:100%;
height:100%;

@media screen and (min-width: 850px){
    width:65%;
}
@media screen and (min-width: 1400px){
    width:50%;
}
`

const Logo = styled.img`
height:400px;
@media screen and (max-width:640px){
    width:65vw;
    height:auto;
}
`
const Paragraph = styled.p`
color:white;
font-size:24px;
font-weight:700;
text-align:center;
margin:35px;
margin-top:20px;
@media screen and (max-width:640px){
font-size:18px ;
}
`

const Date = styled.p`
color:white;
font-size:32px;
font-weight:700;
text-align:center;
margin:35px;
margin-top:35px;
margin-bottom:0;
@media screen and (max-width:640px){
font-size:24px;
}
`

const Light = styled.span`
font-weight:300;
vertical-align:2px ;
`

const Button = styled.a`
text-decoration:none ;
color:white;
border: 4px solid #E94B49;
padding:20px 30px;
text-transform:uppercase ;
font-size:15px;
font-weight:900;
transition: .3s all ease ;

&:hover{
    border: 4px solid #E94B49;
    background-color: #E94B49;
}
`;

const ButtonsWrapper = styled.div`
display:flex;
`

const Image = styled.img`
height:70px;
margin-top:20px;
@media screen and (max-width:640px){
    width:65vw;
    height:auto;
}
`

const HeroSection = () => (
<Wrapper>
    <InfoWrapper>
        <Logo src={heroLogo} alt="logo" />
        <Image src={free} alt="wstęp darmowy"/>
        <Date>30.06 - 2.07.2023 <Light>|</Light> WROCŁAW <Light>|</Light> TOR&nbsp;PARTYNICE</Date>
        <Paragraph>Ponad 55 000 odwiedzających Festiwal Pasibrzucha udowodniło nam, że wiemy czego chcecie! ❤️</Paragraph>
        <ButtonsWrapper>
        <Button href="https://fb.me/e/2CvyuCFsn" target="_blank">wezmę udział!</Button>
        </ButtonsWrapper>
    </InfoWrapper>
</Wrapper>
)

export default HeroSection;