import styled from "styled-components"
import { Link } from "react-router-dom";
import { useContext } from "react"
import Context from "../../context"
import fbIcon from "../../images/fb.svg"
import instaIcon from "../../images/insta.svg"

const Wrapper = styled.div`
position:absolute;
display:flex;
flex-direction:column ;
align-items:flex-end ;
background-color:#131339;
width:100vw;
height:calc(100vh - 100px);
right:0;
top:100px;
z-index:1;
border-top:2px solid white;
padding:50px;
gap:25px;
overflow-y:scroll ;
@keyframes appear-desktop{
    from{right:-500px}
    to{right:0}
}
@keyframes appear-mobile{
    from{right:-100vw}
    to{right:0}
}
animation: appear-mobile .3s forwards; 

@media screen and (min-width:1000px){
    width:500px;
    animation: appear-desktop .3s forwards; 
}
`

const MenuItem = styled.p`
position:relative;
display:flex;
align-items:center ;
color:white;
font-size:20px;
font-weight:900;
text-transform:uppercase ;
&:before{
        position:absolute;
        content:"";
        display:block;
        background-color:#E94B49;
        width:0;
        height:4px;
        bottom:-7px;
        right:0;
        transition: all .3s ease;
    }
&:hover{
    &:before{
        position:absolute;
        content:"";
        display:block;
        background-color:#E94B49;
        width:100%;
        height:4px;
        bottom:-7px;
        right:0;
    }
}
`



const HyperLink = styled.a`
position:relative;
display:flex;
align-items:center ;
text-decoration:none;
color:white;
font-size:20px;
font-weight:900;
text-transform:uppercase ;
&:before{
        position:absolute;
        content:"";
        display:block;
        background-color:#E94B49;
        width:0;
        height:4px;
        bottom:-7px;
        right:0;
        transition: all .3s ease;
    }
&:hover{
    &:before{
        position:absolute;
        content:"";
        display:block;
        background-color:#E94B49;
        width:100%;
        height:4px;
        bottom:-7px;
        right:0;
    }
}
`


const Socials = styled.div`
    display:flex;
    position:relative;
    justify-content:space-between ;
    border-top:2px solid white;
    padding-top:15px;
    width:90px;
    margin-top:15px;
`

const SocialLink = styled.a`
display:inline-block;
`

const Menu = ()=>{
    const { dispatch } = useContext(Context);
    return (
    <Wrapper>
        <Link to="/" onClick={()=>dispatch("TOGGLE_MENU")}><MenuItem>HOME</MenuItem></Link>
        <Link to="/o-festiwalu" onClick={()=>{dispatch("TOGGLE_MENU"); window.scrollTo(0, 0);}}><MenuItem>O FESTIWALU</MenuItem></Link>
        <Link to="/harmonogram" onClick={()=>{dispatch("TOGGLE_MENU"); window.scrollTo(0, 0);}}><MenuItem>HARMONOGRAM</MenuItem></Link>
        <Link to="/partnerzy" onClick={()=>{dispatch("TOGGLE_MENU"); window.scrollTo(0, 0);}}><MenuItem>PARTNERZY</MenuItem></Link>
        <HyperLink href="https://festiwalpasibrzucha.payeye.com/" target="_blank">PAYEYE -20%</HyperLink>
        <HyperLink href="https://festiwalpasibrzucha.payeye.com/konkurs/" target="_blank">PASITERIA PAYEYE</HyperLink>
        <Link to="/aktualnosci" onClick={()=>{dispatch("TOGGLE_MENU"); window.scrollTo(0, 0);}}><MenuItem>AKTUALNOŚCI</MenuItem></Link>
        <Link to="/mapa" onClick={()=>{dispatch("TOGGLE_MENU"); window.scrollTo(0, 0);}}><MenuItem>MAPA FESTIWALU</MenuItem></Link>
        <Link to="/wystawcy" onClick={()=>{dispatch("TOGGLE_MENU"); window.scrollTo(0, 0);}}><MenuItem>WYSTAWCY</MenuItem></Link>
        <Link to="/jak-dojechac" onClick={()=>{dispatch("TOGGLE_MENU"); window.scrollTo(0, 0);}}><MenuItem>JAK DOJECHAĆ</MenuItem></Link>
        <Link to="/regulamin" onClick={()=>{dispatch("TOGGLE_MENU"); window.scrollTo(0, 0);}}><MenuItem>REGULAMINY</MenuItem></Link>
        <Link to="/kontakt" onClick={()=>{dispatch("TOGGLE_MENU"); window.scrollTo(0, 0);}}><MenuItem>KONTAKT</MenuItem></Link>
        <Socials>
            <SocialLink href="https://www.facebook.com/events/1763141343870699/" target="_blank"><img src={fbIcon} alt="" /></SocialLink>
            <SocialLink href="https://www.instagram.com/festiwalpasibrzucha/" target="_blank"><img src={instaIcon} alt="" /></SocialLink>
        </Socials>
    </Wrapper>
    )
}

export default Menu;

