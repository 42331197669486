import GlobalStyle from "./GlobalStyle";
import StateProvider from "./StateProvider";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import MainPage from "./components/MainPage/MainPage"
import LineUp from "./components/LineUp/LineUp";
import News from "./components/News/News";
import { news } from "./data";
import AllNews from "./components/AllNews/AllNews";
import Exhibitors from "./components/Exhibitors/Exhibitors";
import About from "./components/About/About";
import PartnersAll from "./components/PartnersAll/PartnersAll";
import Contact from "./components/Contact/Contact";
import Directions from "./components/Directions/Directions";
import Regulations from "./components/Regulations/Regulations";
import Map from "./components/Map/Map";
import  GalleryFriday  from "./components/Gallery/GalleryFriday";
import  GallerySaturday  from "./components/Gallery/GallerySaturday";
import  GallerySunday  from "./components/Gallery/GallerySunday";
import Aftermovie from "./components/Gallery/Aftermovie";


const App = () =>{
  return (
  <StateProvider>
    <GlobalStyle/>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="harmonogram" element={<LineUp />} />
      <Route path="aktualnosci" element={<AllNews />} />
      <Route path="wystawcy" element={<Exhibitors />} />
      <Route path="aktualnosci/galeria-piatek" element={<GalleryFriday />} />
      <Route path="aktualnosci/galeria-sobota" element={<GallerySaturday />} />
      <Route path="aktualnosci/galeria-niedziela" element={<GallerySunday />} />
      <Route path="aktualnosci/aftermovie" element={<Aftermovie />} />
      <Route path="o-festiwalu" element={<About />} />
      <Route path="partnerzy" element={<PartnersAll />} />
      <Route path="kontakt" element={<Contact />} />
      <Route path="mapa" element={<Map />} />
      <Route path="jak-dojechac" element={<Directions />} />
      <Route path="regulamin" element={<Regulations />} />
      {news.map(el=><Route path={`aktualnosci/${el.slug}`} element={<News news={el}/>} key={el.id}/>)}
      </Routes>
    </BrowserRouter>
  </StateProvider>
  )
}

export default App;

