import styled from "styled-components";
import Footer from "../Footer/Footer";
import Heading from "../Heading/Heading";
import Navigation from "../Navigation/Navigation";

const Wrapper = styled.div`
position:relative;
`

const ThreeColumnGrid = styled.div`
margin:0 auto;
display:flex;
flex-direction:row ;
@media screen and (max-width:1000px){
    flex-direction:column ;
}
@media screen and (min-width:1400px){
    padding:0 10vw;
}
`

const HeadingWrapper = styled.div`
@media screen and (min-width:1400px){
    padding:0 10vw;
}
`

const Column = styled.div`
display:flex;
flex-direction:column ;
align-items:center;
justify-content:flex-start ;
flex-grow:1;
flex-shrink:1;
flex-basis:33.333% ;
padding:0 40px;
height:100%;
border-left: 4px dotted #131339;
&:first-child{
    border:0;
}
@media screen and (max-width:1000px){
    flex-basis:100%;
    border:0;
    margin-bottom:30px;
}
@media screen and (min-width:1400px){
    &:first-child{
        border-left: 4px dotted #131339;
    }
    &:last-child{
        border-right: 4px dotted #131339;
    }
}
`
const Row = styled.div`
display:flex;
flex-direction:row;
justify-content:center;
flex-wrap:wrap;
gap:10px;
padding:0 25px;
@media screen and (min-width:1400px){
    padding:0 10vw;
}
`

const DayName = styled.p`
background-color:#5AB1F7;
color:white;
text-align:center ;
font-size:24px;
font-weight:900;
text-transform:uppercase ;
width:100%;
padding:20px 20px;
margin-bottom:30px;
`

const HighlightWrapper = styled.div`
display:flex;
flex-direction:column ;
align-items:center ;
margin: 10px 0;
width:100%;
`

const HighlightName = styled.p`
text-align:center ;
font-size:16px;
font-weight:700;
text-transform:uppercase ;
`

const HighlightPrimaryName = styled.p`
text-align:center ;
font-size:24px;
font-weight:900;
text-transform:uppercase ;
border:3px solid black;
padding:10px 20px;


`
const HyperLink = styled.a`
outline:0;
text-decoration: none;
border:0;
color:#5AB1F7;
&:hover{
    opacity:.5;
}
`

const HighlightTime = styled.p`
color:#5AB1F7;
font-size:20px;
font-weight:800;
`

const Light = styled.span`
font-weight:500;
vertical-align:1px ;
`

const Highlight = (props) => {
    return (
        <HighlightWrapper>
        {props.primary?<HighlightPrimaryName>{props.name}</HighlightPrimaryName>:<HighlightName>{props.name}</HighlightName>}
        {props.time?<HighlightTime>{props.time}</HighlightTime>:null}
        </HighlightWrapper>
    )
}

const LineUp = () => 
<Wrapper>
<Navigation/>
<HeadingWrapper><Heading>na festiwalu</Heading></HeadingWrapper>
<Row>
<HyperLink href="/aktualnosci/strefa-gastro"><HighlightPrimaryName>50 Foodtrucków (strefa gastro)</HighlightPrimaryName></HyperLink>
<HighlightPrimaryName>Pasigames</HighlightPrimaryName>
<HighlightPrimaryName>strefa tattoo &amp; barber</HighlightPrimaryName>
<HighlightPrimaryName>konikowo</HighlightPrimaryName>
<HyperLink href="/aktualnosci/partner-coca-cola"><HighlightPrimaryName>strefa chillu coca-coli</HighlightPrimaryName></HyperLink>
<HyperLink href="/aktualnosci/wroclaw-craft-zone"><HighlightPrimaryName>wrocław craft zone</HighlightPrimaryName></HyperLink>
<HyperLink href="/aktualnosci/atrakcje-dla-dzieci"><HighlightPrimaryName>strefa kids</HighlightPrimaryName></HyperLink>
<HyperLink href="/aktualnosci/strefa-audi"><HighlightPrimaryName>strefa audi</HighlightPrimaryName></HyperLink>
<HyperLink href="/aktualnosci/pasiteria"><HighlightPrimaryName>pasiteria by payeye</HighlightPrimaryName></HyperLink>
<HyperLink href="/aktualnosci/buh-partnerem-strategicznym"><HighlightPrimaryName>strefa buh</HighlightPrimaryName></HyperLink>
<HighlightPrimaryName>strefa moto</HighlightPrimaryName>
<HyperLink href="https://www.facebook.com/events/583109019661921" target="_blank"><HighlightPrimaryName>survival race kids</HighlightPrimaryName></HyperLink>
<HighlightPrimaryName>strefa gier omg!</HighlightPrimaryName>
</Row>
<HeadingWrapper><Heading>harmonogram</Heading></HeadingWrapper>
<ThreeColumnGrid>
<Column>
<DayName>PIĄTEK <Light>|</Light> 1.07</DayName>
<Highlight name="start 17:00" primary/>
<Highlight name="Oficjalne rozpoczęcie Festiwalu" time="17:00"/>
<Highlight name="Panel dyskusyjny" time="17:30"/>
<Highlight name="DJ SET" time="18:00"/>
<Highlight name="STAND-UP" primary/>
<Highlight name="Konrad Lis" time="20:00"/>
<Highlight name="Michał Juszczak" time="20:20"/>
<Highlight name="Alex Niebrzegowski" time="20:40"/>
<HyperLink href="/aktualnosci/pasiteria"><Highlight name="Pasiteria PayEye - Finał" time="21:15"/></HyperLink>
<HyperLink href="/aktualnosci/koncerty-ostr-letni"><Highlight name="Koncert" primary/></HyperLink>
<Highlight name="O.S.T.R." time="21:30"/>
<Highlight name="DJ SET" time="22:30"/>
<HyperLink href="/aktualnosci/kino-plenerowe"><Highlight name="KINO NOCNE" primary/></HyperLink>
<Highlight name="Pulp Fiction" time="23:30"/>
</Column>
<Column>
<DayName>SOBOTA <Light>|</Light> 2.07</DayName>
<Highlight name="start 10:00" primary/>
<HyperLink href="/aktualnosci/atrakcje-dla-dzieci"><Highlight name="Survival Race Kids" time="10:00 - 12:00"/></HyperLink>
<HyperLink href="/aktualnosci/kino-plenerowe"><Highlight name="KINO FAMILIJNE" primary/></HyperLink>
<Highlight name="SING" time="10:30"/>
<Highlight name="DJ SET" time="13:00"/>
<HyperLink href="/aktualnosci/konkurs-foodsi"><Highlight name="Finał Konkursu Best Food Truck x Foodsi" time="15:30"/></HyperLink>
<HyperLink href="/aktualnosci/magiczna-scena"><Highlight name="MAGIC SHOW" primary/></HyperLink>
<Highlight name="Daniel Jedynak" time="16:00"/>
<Highlight name="Łukasz Grant" time="17:30"/>
<Highlight name="Maciej Pęda" time="18:30"/>
<HyperLink href="/aktualnosci/koncerty-ostr-letni"><Highlight name="KONCERTY" primary/></HyperLink>
<Highlight name="Prosto z Mostów" time="19:00"/>
<Highlight name="Faustina Calavera" time="20:00"/>
<HyperLink href="/aktualnosci/pasiteria"><Highlight name="Pasiteria PayEye - Finał" time="21:15"/></HyperLink>
<Highlight name="LETNI" time="21:30"/>
<HyperLink href="/aktualnosci/kino-plenerowe"><Highlight name="KINO NOCNE" primary/></HyperLink>
<Highlight name="American Pie: Zjazd Absolwentów" time="23:30"/>
</Column>
<Column>
<DayName>NIEDZIELA <Light>|</Light> 3.07</DayName>
<Highlight name="start 10:00" primary/>
<HyperLink href="/aktualnosci/atrakcje-dla-dzieci"><Highlight name="Survival Race Kids" time="10:00 - 12:00"/></HyperLink>
<HyperLink href="/aktualnosci/kino-plenerowe"><Highlight name="KINO FAMILIJNE" primary/></HyperLink>
<Highlight name="Sekretne Życie Zwierzaków Domowych" time="10:30"/>
<Highlight name="DJ SET" time="13:00"/>
<Highlight name="BLOK KULINARNY" primary/>
<Highlight name="High Octane - Picie piwa na czas" time="14:00"/>
<Highlight name="Dziki Bill - Konkurs jedzenia ostrych papryczek" time="14:20"/>
<Highlight name="Krasnolód - Blind Testy" time="15:30"/>
<Highlight name="High Octane - Picie piwa na czas" time="16:10"/>
<Highlight name="Gastro Small Talk" time="16:30"/>
<HyperLink href="/aktualnosci/koncerty-ostr-letni"><Highlight name="KONCERTY" primary/></HyperLink>
<Highlight name="KTO" time="17:00"/>
<Highlight name="Niedzielski" time="18:00"/>
<Highlight name="Uvaga" time="19:00"/>
<Highlight name="Luiza Kalita" time="20:00"/>
<HyperLink href="/aktualnosci/pasiteria"><Highlight name="Pasiteria PayEye - Finał" time="21:15"/></HyperLink>
<Highlight name="DJ SET" time="21:00"/>
</Column>
</ThreeColumnGrid>
<Footer/>
</Wrapper>

export default LineUp;