import styled from "styled-components"
import Heading from "../Heading/Heading"
import Navigation from "../Navigation/Navigation"
import Footer from "../Footer/Footer"

const Wrapper = styled.div`
display:flex;
flex-direction:column;
@media screen and (max-width:870px){
    margin-bottom:0;
}
@media screen and (min-width:1400px){
    padding:0 10vw;
}
`

const Link = styled.a`
display:flex;
justify-content:center;
align-items:center;
color:white;
background-color:#131339;
font-weight:700;
font-size:20px;
padding:25px;
&:hover{
    opacity:.4;
}
@media screen and (max-width:870px){
    font-size:16px;
}
`

const LinkWrapper = styled.div`
display:flex;
flex-direction:column;
gap:10px;
padding:50px;
`

const Regulations = () => {
    return (
        <>
        <Navigation/>
        <Wrapper>
        <Heading>regulaminy</Heading>
        <LinkWrapper>
        <Link href="https://pasibus.blob.core.windows.net/media/3728/regulamin-uczestnika-festiwalu-pasibrzucha_31-05-2022-docx.pdf">Regulamin Uczestnika Festiwalu</Link>
        <Link href="https://pasibus.blob.core.windows.net/media/3835/festiwal-pasibrzucha-2022_regulamin-dla-u%C5%BCytkownik%C3%B3w.pdf">Regulamin PAYEYE Uczestnika Festiwalu</Link>
        <Link href="https://pasibus.blob.core.windows.net/media/3727/regulamin-wystawc%C3%B3w-festiwal-pasibrzucha-31-05-2022-docx.pdf">Regulamin Wystawcy Festiwalu</Link>
        <Link href="https://pasibus.blob.core.windows.net/media/3834/festiwal-pasibrzucha-2022-regulamin-dla-wystawcy.pdf">Regulamin PAYEYE Wystawcy Festiwalu</Link>
        <Link href="https://pasibus.blob.core.windows.net/media/3726/regulamin-wystawcy-wroc%C5%82aw-craft-zone-festiwalu-pasibrzucha-2022-docx.pdf">Regulamin Wystawcy Festiwalu - Craft Zone</Link>
        </LinkWrapper>
        </Wrapper>
        <Footer/>
        </>
    )
}

export default Regulations;