import styled from "styled-components";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import Heading from "../Heading/Heading";
import NewsShort from "../NewsShort/NewsShort";
import Video from "../Video/Video"
import afterMovie from "../../images/after_movie.mp4";

const Wrapper = styled.div`
position:relative;
`

const Description = styled.p`
margin-bottom:50px;
text-align:justify ;
`

const VideoWrapper = styled.div`

  height: auto;
  overflow: hidden;
  display:flex;
  flex-direction:column ;
  align-items:flex-start ;
  @media screen and (min-width:1400px){
    padding:0 10vw;
}
  /* @media (min-width: 1075px) {
    height: 42vw;
  } */
`;

const Aftermovie = ()=>{
  window.scrollTo(0, 0);
    
    return (
        <Wrapper>
            <Navigation/>
           
            <VideoWrapper>
            <Heading>Wróćmy na Festiwal Pasibrzucha jeszcze raz!</Heading>
            <Description>
            Z początkiem wakacji wystartowała II edycja Festiwal PasiBrzucha  Coż to był za weekend pełen emocji!  <br/><br/>
Koncerty , food trucki , stand up , challenge , Survival Race Kids to tylko niektóre z MNÓSTWA atrakcji które czekały na Was na festiwalu we Wrocławiu!
Dziękujemy wszystkim i każdemu z osobna!  Widzimy się za rok na III edycji!  <br/><br/>
Tymczasem zachęcamy Was do obejrzenia relacji z wydarzenia - mamy ciarki na plecach jak ją oglądamy! 
 HEXO Group, serdeczne dzięki za podsumowanie tych wspaniałych 3 dni!
            </Description>
        <Video
          loop
          muted={true}
          autoPlay={true}
          playsInline={true}
          controls={true}
          width="100vw"
          height="auto"
        >
          <source src={afterMovie} type="video/mp4" />
        </Video>
</VideoWrapper>
        <NewsShort/>
        <Footer/>
        </Wrapper>
        
    );
}

export default Aftermovie;