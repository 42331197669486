import styled from "styled-components"
import Heading from "../Heading/Heading"
import { news } from "../../data"
import News from "./News"
import Navigation from "../Navigation/Navigation"
import Footer from "../Footer/Footer"
import GalleryFridayThumbnail from "../Gallery/GalleryFridayThumbnail"
import GallerySaturdayThumbnail from "../Gallery/GallerySaturdayThumbnail"
import GallerySundayThumbnail from "../Gallery/GallerySundayThumbnail"
import AftermovieThumbnail from "../Gallery/AftermovieThumbnail"

const Wrapper = styled.div`
display:flex;
flex-direction:column;
@media screen and (min-width:1400px){
    padding:50px 10vw;
}
`

const NewsWrapper = styled.div`
display:flex;
flex-direction:row;
padding:0 50px;
flex-wrap:wrap;
@media screen and (min-width:1400px){
padding:0;
}
`



const AllNews = () => {
 const allnews = news.reverse();
    return (
        <>
        <Navigation/>
        <Wrapper>
        <Heading>Aktualności</Heading>
        <NewsWrapper>
            <AftermovieThumbnail/>
        <GallerySundayThumbnail/>
        <GallerySaturdayThumbnail/>
        <GalleryFridayThumbnail/>
            {allnews.map(el=><News news={el}/>)}
        </NewsWrapper>
        </Wrapper>
        <Footer/>
        </>
    )
}

export default AllNews;