import styled from "styled-components"
import Heading from "../Heading/Heading"
import { mainnews } from "../../data"
import News from "./News"
import GalleryFridayThumbnail from "../Gallery/GalleryFridayThumbnail"
import GallerySaturdayThumbnail from "../Gallery/GallerySaturdayThumbnail"
import GallerySundayThumbnail from "../Gallery/GallerySundayThumbnail"
import Aftermovie from "../Gallery/AftermovieThumbnail"

const Wrapper = styled.div`
display:flex;
flex-direction:column;
@media screen and (min-width:1400px){
    padding:50px 10vw;
}
`

const NewsWrapper = styled.div`
display:flex;
flex-direction:row;
padding:0 50px;
flex-wrap:wrap;
@media screen and (min-width:1400px){
padding:0;
}
`



const NewsShort = () => {

    const lastEight = mainnews.slice(-4,mainnews.length).reverse()
    return (
        <Wrapper>
        <Heading>Aktualności</Heading>
        <NewsWrapper>
        <Aftermovie/>
            <GallerySundayThumbnail/>
            <GallerySaturdayThumbnail/>
            <GalleryFridayThumbnail/>
            
            {lastEight.map(el=><News news={el}/>)}
        </NewsWrapper>
        </Wrapper>
    )
}

export default NewsShort;