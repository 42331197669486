import styled from "styled-components"
import heroLogo from "../../images/hero_logo.png"
import { Link } from "react-router-dom"

const Wrapper = styled.div`
display:flex;
position:relative;
justify-content:center ;
align-items:center ;
height:350px;
background-color:#131339;
margin-top:100px;
@media screen and (max-width:870px){
   height:unset;
   padding:35px 0;
}
`

const Logo = styled.img`
height:400px;
margin-top:-100px;
@media screen and (max-width:870px){
    display:none;
}
@media screen and (max-width:600px){
    width:70vw;
    height:auto;
    margin-bottom:-25px;
}
@media screen and (max-width:1020px){
    width:30vw;
    height:auto;
}
`
const LogoMobile = styled.img`
@media screen and (max-width:870px){
    height:400px;
    margin-top:-100px;
    margin-bottom:20px;
}
`

const FlexWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center ;
flex-direction:row ;
padding: 0 50px;
height:350px;
width:1000px;
gap:100px;
@media screen and (max-width:870px){
  display:none;
}
@media screen and (max-width:1400px){
    width:100vw;
}
@media screen and (max-width:1020px){
    gap:50px;
}
`

const MobileWrapper = styled.div`
display:none;
@media screen and (max-width:870px){
  display:flex;
  flex-direction:column;
  gap:15px;
  padding:20px 0;
}
`

const MenuItem = styled.p`
position:relative;
display:flex;
align-items:center ;
color:white;
font-size:20px;
font-weight:900;
text-transform:uppercase ;
&:hover{
    opacity:.4;
}
@media screen and (max-width:1020px){
    font-size:18px;
}
@media screen and (max-width:870px){
    font-size:24px;
}
`



const HyperLink = styled.a`
position:relative;
display:flex;
align-items:center ;
text-decoration:none;
color:white;
font-size:20px;
font-weight:900;
text-transform:uppercase ;
&:hover{
    opacity:.4;
}
@media screen and (max-width:1020px){
    font-size:18px;
}
@media screen and (max-width:870px){
    font-size:24px;
}
`


const TextWrapper = styled.div`
display:flex;
flex-direction:column ;
justify-content:center ;
color:white;
gap:15px;
@media screen and (max-width:870px){
  align-items:center;
  justify-content:space-between ;
}
`

const Footer = () => {

    return (
        <Wrapper>
            <FlexWrapper>
                <TextWrapper>
                <Link to="/" onClick={()=>window.scrollTo(0, 0)}><MenuItem>HOME</MenuItem></Link>
        <Link to="/o-festiwalu" onClick={()=>window.scrollTo(0, 0)}><MenuItem>O FESTIWALU</MenuItem></Link>
        {/* <Link to="/harmonogram" onClick={()=>window.scrollTo(0, 0)}><MenuItem>HARMONOGRAM</MenuItem></Link>
        <Link to="/partnerzy" onClick={()=>window.scrollTo(0, 0)}><MenuItem>PARTNERZY</MenuItem></Link>
        <HyperLink href="https://festiwalpasibrzucha.payeye.com/" target="_blank">PAYEYE -20%</HyperLink>
        <HyperLink href="https://festiwalpasibrzucha.payeye.com/konkurs/" target="_blank">PASITERIA PAYEYE</HyperLink> */}
                </TextWrapper>
                <TextWrapper>
                <Link to="/aktualnosci" onClick={()=>window.scrollTo(0, 0)}><MenuItem>AKTUALNOŚCI</MenuItem></Link>
        {/* <Link to="/mapa" onClick={()=>window.scrollTo(0, 0)}><MenuItem>MAPA FESTIWALU</MenuItem></Link>
        <Link to="/wystawcy" onClick={()=>window.scrollTo(0, 0)}><MenuItem>WYSTAWCY</MenuItem></Link> */}
        <Link to="/jak-dojechac" onClick={()=>window.scrollTo(0, 0)}><MenuItem>JAK DOJECHAĆ</MenuItem></Link>
        {/* <Link to="/regulamin" onClick={()=>window.scrollTo(0, 0)}><MenuItem>REGULAMINY</MenuItem></Link> */}
        <Link to="/kontakt" onClick={()=>window.scrollTo(0, 0)}><MenuItem>KONTAKT</MenuItem></Link>
                </TextWrapper>
                <Logo src={heroLogo}/>
            </FlexWrapper>
            <MobileWrapper>
            <LogoMobile src={heroLogo}/>
                <TextWrapper>
                <Link to="/" onClick={()=>window.scrollTo(0, 0)}><MenuItem>HOME</MenuItem></Link>
        <Link to="/o-festiwalu" onClick={()=>window.scrollTo(0, 0)}><MenuItem>O FESTIWALU</MenuItem></Link>
        {/* <Link to="/harmonogram" onClick={()=>window.scrollTo(0, 0)}><MenuItem>HARMONOGRAM</MenuItem></Link> */}
        {/* <Link to="/partnerzy" onClick={()=>window.scrollTo(0, 0)}><MenuItem>PARTNERZY</MenuItem></Link> */}
        {/* <HyperLink href="https://festiwalpasibrzucha.payeye.com/" target="_blank">PAYEYE -20%</HyperLink> */}
        {/* <HyperLink href="#">PASITERIA</HyperLink> */}
                </TextWrapper>
                <TextWrapper>
                <Link to="/aktualnosci" onClick={()=>window.scrollTo(0, 0)}><MenuItem>AKTUALNOŚCI</MenuItem></Link>
        {/* <Link to="/mapa-festiwalu" onClick={()=>window.scrollTo(0, 0)}><MenuItem>MAPA FESTIWALU</MenuItem></Link> */}
        {/* <Link to="/wystawcy" onClick={()=>window.scrollTo(0, 0)}><MenuItem>WYSTAWCY</MenuItem></Link> */}
        <Link to="/jak-dojechac" onClick={()=>window.scrollTo(0, 0)}><MenuItem>JAK DOJECHAĆ</MenuItem></Link>
        {/* <Link to="/regulamin" onClick={()=>window.scrollTo(0, 0)}><MenuItem>REGULAMINY</MenuItem></Link> */}
        <Link to="/kontakt" onClick={()=>window.scrollTo(0, 0)}><MenuItem>KONTAKT</MenuItem></Link>
                </TextWrapper>
                
            </MobileWrapper>
        </Wrapper>
    )
}

export default Footer;